import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useIsEmployeeView } from '../../../hooks/id_token_claims'
import DoppelFormLabel from '../../shared/forms/doppel_form_label'
import { ReactElement, ReactNode, useState } from 'react'
import { DOPPEL_CYBER_BLUE } from '@/utils/style'
import {
  useUpdateEnforcementRequestRetractionInfoMutation,
  useUpdateEnforcementRequestStatusMutation,
} from '@/generated/graphql'
import {
  ENFORCEMENT_STATUS,
  PRODUCT_SPECIFIC_RETRACTION_REASONS,
  RETRACTION_REASONS,
} from '@/utils/constants'
import SpoofDetailImagePreview from '../../web2/detail_modal/spoof_detail_image_preview'
import DropdownSelector from '@/components/shared/dropdown_selector'
import { MdArrowDropDown } from 'react-icons/md'
import { ProductType } from '@/utils/constants'
import { uploadRetractionScreenshotToGCS } from './retraction_utils'
import RetractionScreenshotDropbox from './retraction_screenshot_dropbox'

const SCREENSHOT_MAX_HEIGHT = '100px'
const RETRACTION_REASON_OTHER = 'Other'

type RetractionModalProps = {
  enforcementRequest: any
  onClose: () => void
  productType?: ProductType | undefined | null
}

// Retraction modal used start retracting an enforcement
// It's a form with a text input and an image dropbox
// This form can only be seen internally
function RetractionModal({
  enforcementRequest,
  onClose,
  productType,
}: RetractionModalProps): ReactElement {
  const toast = useToast()
  const retractionReasonOptions = RETRACTION_REASONS.concat(
    PRODUCT_SPECIFIC_RETRACTION_REASONS.get(productType) ?? [],
  ).concat([RETRACTION_REASON_OTHER])
  const [dropdownRetractionReason, setDropdownRetractionReason] = useState('')

  // Mutation to update the retraction info in db
  const [updateEnforcementRequestRetractionInfo] =
    useUpdateEnforcementRequestRetractionInfoMutation()
  // Mutation to update the status in db
  const [updateEnforcementRequestStatus] = useUpdateEnforcementRequestStatusMutation()

  // Used to show submission is loading in the button
  const [submissionLoading, setSubmissionLoading] = useState(false)

  // This aggregates the information from the retraction form
  // Everything in here is required to submit
  const [retractionFormInfo, setRetractionFormInfo] = useState({
    retractionDescription: '',
    screenshotFile: null,
  })

  const [isEmployeeView] = useIsEmployeeView()
  if (!isEmployeeView) return <></>

  function updateRetractionReason(newReason: string) {
    setDropdownRetractionReason(newReason)
    let newRetractionDescription = ''
    if (newReason !== RETRACTION_REASON_OTHER) {
      newRetractionDescription = newReason
    }
    setRetractionFormInfo((prevState) => ({
      ...prevState,
      retractionDescription: newRetractionDescription,
    }))
  }

  // Textbox that takes in the description of the retraction
  function RetractionReasonDescriptionSection(): ReactNode {
    return (
      <VStack align="left">
        <FormControl isRequired>
          <VStack align="left">
            <DoppelFormLabel
              formLabel="Retraction Reason"
              helperText="Select a reason why this enforcement request is being retracted. Please select 'Other' to write a custom reason."
            />

            <Box>
              <DropdownSelector
                borderRadius="md"
                fontSize="md"
                height="40px"
                icon={<MdArrowDropDown />}
                items={retractionReasonOptions}
                onItemSelected={(newReason) => updateRetractionReason(newReason)}
                overflow="hidden"
                selectedItem={dropdownRetractionReason}
                size="m"
                style={{ padding: '10px' }}
                textOverflow="ellipsis"
                width="100%"
              />
            </Box>
          </VStack>
        </FormControl>

        {dropdownRetractionReason === RETRACTION_REASON_OTHER && (
          <FormControl isRequired>
            <VStack align="left">
              <DoppelFormLabel
                formLabel="Retraction Text Description"
                helperText="Since you selected other, please enter a description of why this enforcement request is being retracted."
              />

              <Box>
                <Textarea
                  maxHeight={SCREENSHOT_MAX_HEIGHT}
                  onChange={(e) => {
                    setRetractionFormInfo((prevState) => ({
                      ...prevState,
                      retractionDescription: e.target.value,
                    }))
                  }}
                  value={retractionFormInfo.retractionDescription}
                />
              </Box>
            </VStack>
          </FormControl>
        )}
      </VStack>
    )
  }

  // Set DB status to retraction started
  // And set retraction screenshot/description columns
  async function UpdateRetractionInfoInDB(screenshotURL: string) {
    let retractionInfoUploaded = false
    await updateEnforcementRequestRetractionInfo({
      variables: {
        id: enforcementRequest.id,
        retraction_description: retractionFormInfo.retractionDescription,
        retraction_proof_url: screenshotURL,
      },
      onError: (error) => {
        setSubmissionLoading(false)
        toast({
          title: 'Error updating enforcement request in database.',
          description: error.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      },
      onCompleted: () => {
        retractionInfoUploaded = true
      },
    })

    // If screenshot not uploaded just exit and don't try to update status
    if (!retractionInfoUploaded) {
      setSubmissionLoading(false)
      return
    }

    await updateEnforcementRequestStatus({
      variables: {
        update_enforcement_request_status_input: {
          enforcement_request_id: enforcementRequest.id,
          enforcement_request_status: ENFORCEMENT_STATUS.RETRACTION_SENT,
          source: 'ui',
        },
      },
      onCompleted: (_) => {
        setSubmissionLoading(false)
        toast({
          title: 'Successfully started retraction process.',
          duration: 2000,
          status: 'success',
          isClosable: true,
        })
        onClose()
      },
      onError: (error) => {
        setSubmissionLoading(false)
        toast({
          title: 'Error changing enforcement status, retraction metadata uploaded',
          description: error.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      },
    })
  }

  async function onSubmit() {
    try {
      const screenshotURL = await uploadRetractionScreenshotToGCS(
        retractionFormInfo.screenshotFile,
        enforcementRequest.id,
      )
      if (!screenshotURL) {
        setSubmissionLoading(false)
        return
      }
      await UpdateRetractionInfoInDB(screenshotURL)
    } catch (error) {
      setSubmissionLoading(false)
      toast({
        title: 'Error uploading retraction screenshot',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }

  function RetractionForm(): ReactNode {
    return (
      <VStack align="left">
        <Heading as="h2" size="md">
          Retraction Creation Form - {enforcementRequest.platform}
        </Heading>

        <HStack
          align={'left'}
          marginBottom={'50px'}
          marginTop={'70px'}
          mx={'50px'}
          spacing={30}
          width="100%"
        >
          <Box flex="0.6">{RetractionReasonDescriptionSection()}</Box>

          <Box flex="0.4">
            {
              <RetractionScreenshotDropbox
                formLabel={
                  <DoppelFormLabel
                    formLabel="Retraction Submission Proof"
                    helperText="Please upload screenshot proof of the retraction submission."
                  />
                }
                screenshotFile={retractionFormInfo.screenshotFile}
                setScreenshotFile={(newScreenshot) =>
                  setRetractionFormInfo((prevState) => ({
                    ...prevState,
                    screenshotFile: newScreenshot,
                  }))
                }
              />
            }
          </Box>
        </HStack>
      </VStack>
    )
  }

  return (
    <Flex width={'1000px'}>
      <Flex left="12px" position="absolute" top="12px" zIndex="2">
        <IconButton
          aria-label="back to database"
          borderRadius="500px"
          icon={<ArrowBackIcon />}
          onClick={onClose}
          size={'sm'}
        ></IconButton>
      </Flex>

      <VStack
        align={'left'}
        marginBottom={'50px'}
        marginTop={'70px'}
        mx={'50px'}
        spacing={30}
        width="100%"
      >
        <Heading as="h4" fontSize={20} size="sm">
          {'Submit Retraction Form'}
        </Heading>

        <SpoofDetailImagePreview isCondensedView={true} />

        {RetractionForm()}

        <Button
          bgColor={DOPPEL_CYBER_BLUE}
          color={'white'}
          isDisabled={!Object.values(retractionFormInfo).every(Boolean)} // Only allow click if everything in retractionInfo is set
          isLoading={submissionLoading}
          loadingText="Uploading"
          onClick={() => {
            setSubmissionLoading(true)
            onSubmit()
          }}
          width={'50%'}
        >
          Submit Retraction
        </Button>
      </VStack>
    </Flex>
  )
}

export default RetractionModal
