import { renderScreenshotDropbox } from '@/utils/enforcement_utils'
import { FormControl, VStack } from '@chakra-ui/react'
import { ReactElement } from 'react'

const SCREENSHOT_MAX_HEIGHT = '100px'

type UploadScreenshotSectionProps = {
  formLabel: ReactElement
  screenshotFile: File | null
  setScreenshotFile: (file: File | null) => void
}

function RetractionScreenshotDropbox({
  formLabel,
  screenshotFile,
  setScreenshotFile,
}: UploadScreenshotSectionProps): ReactElement {
  const screenshotDimensions = {
    maxImgHeight: SCREENSHOT_MAX_HEIGHT,
    maxImgWidth: '75%',
  }
  return (
    <FormControl>
      <VStack align="left">
        {formLabel}

        {renderScreenshotDropbox(
          screenshotFile,
          (newScreenshot) => setScreenshotFile(newScreenshot),
          screenshotDimensions,
        )}
      </VStack>
    </FormControl>
  )
}

export default RetractionScreenshotDropbox
