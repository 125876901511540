import axios from 'axios'

// Helper to upload a file to GCS
// Will be uploaded to enforcement_retraction_screenshots bucket
// File name is enforcement request id
export async function uploadRetractionScreenshotToGCS(
  file: any,
  enforcementRequestId: string,
): Promise<string | null> {
  const formData = new FormData()
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      accept: 'application/json',
    },
  }

  const timestampUTC: string = new Date().toISOString()
  const submissionString = `${enforcementRequestId}_${timestampUTC}`

  formData.append('filename', submissionString)
  // Want screenshot to go to enforcement_retraction_screenshots bucket
  formData.append('type', 'enforcement_retraction_screenshots')
  formData.append('file', file)
  const response = await axios.post(
    'https://us-west1-nft-index.cloudfunctions.net/upload-file-to-gcs',
    formData,
    config,
  )
  return response.data['public_url']
}
