import React, { useEffect, useState } from 'react'
import { Input, InputGroup, InputLeftElement, Tooltip } from '@chakra-ui/react'
import { Order_By, useGetSpoofingReportsLazyQuery } from '@/generated/graphql'
import { isInternalId } from '@/utils/reports/report_utils'
import { getReportsWhereClauseFromExternalIdSlug } from '@/hooks/queries'
import { CheckIcon, CloseIcon, SpinnerIcon } from '@chakra-ui/icons'
import { debounce } from 'lodash'

type ValidationState = 'none' | 'loading' | 'valid' | 'invalid'

export type AlertLookupProps = {
  value: string
  onChange: (value: string) => void
  onResolvedId?: (id: string) => void
  height?: string
  placeholder?: string
  fontSize?: number
  width?: string
  borderRadius?: number
}

export function AlertLookup({
  value,
  onChange,
  onResolvedId,
  height = '35px',
  placeholder = 'Alert ID',
  fontSize = 12,
  width = '300px',
  borderRadius = 8,
}: AlertLookupProps) {
  const [validationState, setValidationState] = useState<ValidationState>('none')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [getSpoofingReports] = useGetSpoofingReportsLazyQuery()

  const resolveAlertId = async (inputId: string): Promise<string | null> => {
    if (!inputId || inputId.length < 4) {
      setValidationState('none')
      setErrorMessage('')
      return null
    }

    setValidationState('loading')

    // Always verify the ID exists, even for internal IDs
    try {
      let whereClause
      if (isInternalId(inputId)) {
        whereClause = { id: { _eq: inputId } }
      } else {
        whereClause = getReportsWhereClauseFromExternalIdSlug(inputId)
        if (!whereClause) {
          setValidationState('invalid')
          setErrorMessage('Invalid ID format')
          return null
        }
      }

      const { data } = await getSpoofingReports({
        variables: {
          spoofingReportsWhere: whereClause,
          orderBy: [{ created_at: Order_By.Asc }],
        },
      })

      if (data && data.spoofing_reports.length > 0) {
        setValidationState('valid')
        setErrorMessage('')
        return data.spoofing_reports[0].id
      }

      setValidationState('invalid')
      setErrorMessage('No matching alert found')
      return null
    } catch (error) {
      setValidationState('invalid')
      setErrorMessage('Failed to fetch alert details')
      return null
    }
  }

  // Create a debounced version of resolveAlertId
  const debouncedResolve = debounce(async (value: string) => {
    const resolvedId = await resolveAlertId(value)
    if (resolvedId && onResolvedId) {
      onResolvedId(resolvedId)
    }
  }, 500)

  useEffect(() => {
    if (value) {
      debouncedResolve(value)
    } else {
      setValidationState('none')
      setErrorMessage('')
    }
    return () => debouncedResolve.cancel()
  }, [value])

  const getValidationIcon = () => {
    if (validationState === 'none') return null
    if (validationState === 'loading') {
      return <SpinnerIcon color="gray.500" />
    }
    if (validationState === 'valid') {
      return <CheckIcon color="green.500" />
    }
    return <CloseIcon color="red.500" />
  }

  return (
    <Tooltip
      isOpen={!!errorMessage}
      label={errorMessage || 'ID of the alert to lookup'}
    >
      <InputGroup width={width}>
        <InputLeftElement h={height} pl={4} pointerEvents="none">
          {getValidationIcon()}
        </InputLeftElement>

        <Input
          borderRadius={borderRadius}
          fontSize={fontSize}
          height={height}
          isInvalid={validationState === 'invalid'}
          maxWidth="full"
          onChange={(e) => onChange(e.target.value)}
          pl={10}
          placeholder={placeholder}
          type="text"
          value={value}
          width="full"
        />
      </InputGroup>
    </Tooltip>
  )
}
