import { ExternalId } from '@/models/alerts'
import {
  Spoofing_Reports_Bool_Exp,
  useGetProductByPlatformQuery,
} from '../generated/graphql'
import { ProductType } from '../utils/constants'

// returns product for a given platform
export function useGetProductByPlatform(platformName: string): ProductType {
  const { data: productData } = useGetProductByPlatformQuery({
    variables: {
      platformName: platformName,
    },
  })
  return productData?.platforms?.[0]?.product
}

function parseExternalIdFromSearch(input: string | null): ExternalId | null {
  if (!input) return null

  // Check if the input starts with 'id:' and extract the part after it
  const match = input.match(/^id:(.+)$/)
  if (!match) return null

  const externalPart = match[1] // Get the part after 'id:'
  const parts = externalPart.split('-')

  // If there's no dash, assume no abbreviation, only externalId
  if (parts.length === 1) {
    return {
      orgAbbr: null, // No abbreviation
      idNumber: Number(parts[0]), // The whole part is the external ID
    }
  } else if (parts.length === 2) {
    return {
      orgAbbr: parts[0], // The part before the dash is the organization abbreviation
      idNumber: Number(parts[1]), // The part after the dash is the external ID
    }
  }

  return null // Invalid format if there are more than 2 parts
}

export function parseExternalId(
  input: string | null,
  orgId: string | null = null,
): ExternalId | null {
  if (!input) return null

  const parts = input.split('-')

  if (parts.length === 2) {
    // If the input has an organization abbreviation and external ID (slug format)
    const externalId = Number(parts[1])
    if (isNaN(externalId)) return null

    return {
      orgAbbr: parts[0],
      idNumber: externalId,
    }
  } else if (parts.length === 1 && orgId) {
    // If only the external ID is provided (using org context)
    const externalId = Number(parts[0])
    if (isNaN(externalId)) return null

    return {
      orgAbbr: null,
      idNumber: externalId,
    }
  }

  return null // Invalid format
}

export function getWhereClauseFromExternalId(
  externalId: ExternalId | null,
  orgId: string | null = null,
): Spoofing_Reports_Bool_Exp | null {
  if (!externalId) return null
  const { orgAbbr, idNumber } = externalId
  const spoofingReportsWhere: Spoofing_Reports_Bool_Exp = {
    external_id: { _eq: idNumber },
  }
  if (orgAbbr) {
    spoofingReportsWhere.organization = {
      abbr_name: { _eq: orgAbbr.toUpperCase() },
    }
  }
  if (orgId) {
    spoofingReportsWhere.organization_id = { _eq: orgId }
  }
  return spoofingReportsWhere
}

export function maybeGetExternalIdWhereClauseFromSearch(
  externalIdSlug: string | null,
  orgId: string | null = null,
): Spoofing_Reports_Bool_Exp | null {
  const result = parseExternalIdFromSearch(externalIdSlug)
  return getWhereClauseFromExternalId(result, orgId)
}

export function getReportsWhereClauseFromExternalIdSlug(
  externalIdSlug: string | null,
  orgId: string | null = null,
): Spoofing_Reports_Bool_Exp | null {
  const result = parseExternalId(externalIdSlug, orgId)
  return getWhereClauseFromExternalId(result, orgId)
}

export function getWhereClauseFromExternalIds(
  externalIds: Array<ExternalId>,
  orgId: string | null = null,
): Spoofing_Reports_Bool_Exp | null {
  if (!externalIds || externalIds.length === 0) return null
  const idNumbers = externalIds.map((externalId) => externalId.idNumber)
  const spoofingReportsWhere: Spoofing_Reports_Bool_Exp = {
    external_id: { _in: idNumbers },
  }
  const orgAbbr = externalIds[0].orgAbbr
  spoofingReportsWhere.organization = {
    abbr_name: { _eq: orgAbbr.toUpperCase() },
  }
  if (orgId) {
    spoofingReportsWhere.organization_id = { _eq: orgId }
  }
  return spoofingReportsWhere
}

export function getReportsWhereClauseFromExternalIds(
  externalIds,
  orgId: string | null = null,
): Spoofing_Reports_Bool_Exp | null {
  const result = externalIds
    .map((externalId) => parseExternalId(externalId, orgId))
    .filter((id) => id !== null)
  return getWhereClauseFromExternalIds(result, orgId)
}
