import React, { useState } from 'react'
import { HStack, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import DoppelDefaultButton from '../shared/doppel_default_button'
import DropdownSelector from '../shared/dropdown_selector'
import { AlertLinkRelationship, AlertLinkType } from '@/generated/enums'
import { useCreateAlertLinkMutation } from '@/generated/graphql'
import DoppelAlertDialog from '../shared/doppel_alert_dialog'
import { AUTOGENERATED_LINK_TYPES } from '@/utils/detailed_view/alert_linking_utils'
import { AlertLookup } from './alert_lookup'

export type AlertLinkAdderProps = {
  alertId: string
  alertName: string
  refetchAlertLinks: () => void
}

export function AlertLinkAdder({
  refetchAlertLinks,
  alertId,
  alertName,
}: AlertLinkAdderProps) {
  const [otherAlertId, setOtherAlertId] = useState('')
  const [resolvedAlertId, setResolvedAlertId] = useState('')
  const [alertLinkType, setAlertLinkType] = useState<AlertLinkType | ''>('')
  const [alertLinkRelationship, setAlertLinkRelationship] = useState<
    AlertLinkRelationship | ''
  >('')

  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  const height = '35px'

  const [createAlertLinkMutation] = useCreateAlertLinkMutation({
    onCompleted: () => {
      refetchAlertLinks()
      resetFields()
      toast({
        title: 'Successfully created alert link',
        status: 'success',
        isClosable: true,
      })
      onClose()
    },
    onError: (error) => {
      toast({
        title: 'Error creating alert link',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
      onClose()
    },
  })

  const createAlertLink = async () => {
    let alert_id_1 = alertId
    let alert_id_2 = resolvedAlertId || otherAlertId

    let relationship = alertLinkRelationship
    if (alertLinkRelationship === AlertLinkRelationship.PARENT) {
      alert_id_1 = alert_id_2
      alert_id_2 = alertId
      relationship = AlertLinkRelationship.PARENT_CHILD
    } else if (alertLinkRelationship === AlertLinkRelationship.CHILD) {
      // already in correct order
      relationship = AlertLinkRelationship.PARENT_CHILD
    }

    createAlertLinkMutation({
      variables: {
        alert_id_1,
        alert_id_2,
        type: alertLinkType,
        relationship,
      },
    })
  }

  const isCreateLinkDisabled = () => {
    return [otherAlertId, alertLinkType, alertLinkRelationship].some(
      (field) => !field || field === '' || field === null || field === undefined,
    )
  }

  const RELATIONSHIP_OPTIONS = [
    { value: AlertLinkRelationship.PEER_TO_PEER, label: `peer of ${alertName}` },
    { value: AlertLinkRelationship.PARENT, label: `parent of ${alertName}` },
    { value: AlertLinkRelationship.CHILD, label: `child of ${alertName}` },
  ]

  const resetFields = () => {
    setOtherAlertId('')
    setResolvedAlertId('')
    setAlertLinkType('')
    setAlertLinkRelationship('')
  }

  return (
    <HStack alignItems="start" marginBottom={4} pb={4} spacing={4}>
      <AlertLookup
        height={height}
        onChange={setOtherAlertId}
        onResolvedId={setResolvedAlertId}
        placeholder="Linked Alert ID"
        value={otherAlertId}
      />

      <Tooltip label="Alert Link Type indicates the nature of the link between alert">
        <span>
          <DropdownSelector
            height={height}
            idField="alert_link_type"
            items={Object.values(AlertLinkType).filter(
              (type) => !AUTOGENERATED_LINK_TYPES.includes(type),
            )}
            minWidth={'170px'}
            onItemSelected={(value) => setAlertLinkType(value)}
            placeholder="type"
            selectedItem={alertLinkType}
          />
        </span>
      </Tooltip>

      <Tooltip label="Alert Link Relationship indicates the relationship between the linked reports.">
        <span>
          <DropdownSelector
            height={height}
            idField="value"
            items={RELATIONSHIP_OPTIONS}
            minWidth={'170px'}
            nameField="label"
            onItemSelected={(value) => setAlertLinkRelationship(value)}
            placeholder="relationship"
            selectedItem={alertLinkRelationship}
          />
        </span>
      </Tooltip>

      <DoppelDefaultButton
        h={height}
        isDisabled={isCreateLinkDisabled()}
        isInternal={true}
        onClick={onOpen}
        size="md"
      >
        Create Alert Link
      </DoppelDefaultButton>

      <DoppelDefaultButton h={height} onClick={() => resetFields()} size="md">
        Reset
      </DoppelDefaultButton>

      <DoppelAlertDialog
        body={`Are you sure you want to create a link between these alerts?`}
        cancelRef={cancelRef}
        confirmAction={createAlertLink} // Action to create the alert link
        header="Confirm Create Alert Link"
        isOpen={isOpen}
        onClose={onClose}
      />
    </HStack>
  )
}
